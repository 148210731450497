export default [
  { header: "Starterkit", icon: "mdi mdi-dots-horizontal" },

  { id: "1", allowed:"1,2,3,4,5,6", icon: "home", title: "Starter", i18n: "Starter", to: "/dashboard/starter" },
  { id: "2", allowed:"1,2,4,6", icon: "bookmark", title: "Departments", i18n: "Departments", to: "/departments/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Departments",
      to: "/departments/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Departments",
      to: "/departments/",
    },
  ] },
  { id: "3", allowed:"1,2,4,6", icon: "square", title: "Resources", i18n: "Resources", to: "/resources/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Resources",
      to: "/resources/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Resources",
      to: "/resources/",
    },
  ] },
  { id: "4", allowed:"1,2,4,6", icon: "briefcase", title: "Services", i18n: "Services", to: "/services/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Service",
      to: "/services/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Services",
      to: "/services/",
    },
  ] },
  { id: "5", allowed:"1,2", icon: "check-circle", title: "Inspectors", i18n: "Inspectors", to: "/inspectors/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Inspector",
      to: "/inspectors/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Inspectors",
      to: "/inspectors/",
    },
  ] },
  { id: "6", allowed:"1,2,3,4,6", icon: "package", title: "Orders", i18n: "Orders", to: "/orders/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Order",
      to: "/orders/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Orders",
      to: "/orders/",
    },
  ] },
  { id: "7", allowed:"1,2,3,4,6", icon: "award", title: "Bookings", i18n: "Bookings", to: "/bookings/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Booking",
      to: "/bookings/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Bookings",
      to: "/bookings/",
    },
  ] },
  { id: "8", allowed:"1,2,5", icon: "eye", title: "Inspection Jobs", i18n: "Inspection Jobs", to: "/inspectionjobs/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Job",
      to: "/inspectionjobs/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Jobs",
      to: "/inspectionjobs/",
    },
  ] },
  { id: "9", allowed:"1,2,4,6", icon: "users", title: "Users", i18n: "Users", to: "/users/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add User",
      to: "/users/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Users",
      to: "/users/",
    },
  ] },
  { id: "10", allowed:"1,2,4,6", icon: "calendar", title: "Calendars", i18n: "Calendars", to: "/departments/", childern: [
    {
      icon: "mdi mdi-library-plus",
      title: "Add Calender",
      to: "/calendars/add",
    },
    {
      icon: "mdi mdi-library-books", 
      title: "All Calendars",
      to: "/calendars/",
    },
  ] },
];
